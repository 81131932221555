import React from 'react';

import {isEmpty} from "../../helpers/utils";
import ConnectButton from "../ConnectButton/ConnectButton";
import {Apple} from "@material-ui/icons";

const AppleConnectButton = ({onClick, title}) => {
    const getTitle = () => {
        return (isEmpty(title) ? "Continue with Apple" : title);
    }

    return (
        <ConnectButton icon={<Apple />} title={getTitle()} onClick={onClick}/>
    )
}

export default AppleConnectButton;