import React from 'react';
import {FormattedMessage} from 'react-intl';
import GoogleIcon from '../../components/GoogleIcon';

import {isEmpty} from "../../helpers/utils";
import ConnectButton from "../ConnectButton/ConnectButton";

const GoogleConnectButton = ({onClick, title}) => {
    const getTitle = () => {
        if (isEmpty(title)) {
            return (
                <FormattedMessage
                    id="Login.SignInWithGoogle"
                    defaultMessage="Continue with Google"/>
            )
        } else {
            return title;
        }
    }

    return (
        <ConnectButton icon={<GoogleIcon />} title={getTitle()} onClick={onClick}/>
    )
}

export default GoogleConnectButton;