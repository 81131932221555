import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ChangeEmailIcon from '@material-ui/icons/Cached';

const ChangeEmailButton = ({email, onClick}) => {
    return (
        <Button color="primary" endIcon={<ChangeEmailIcon fontSize="small"/>}
                onClick={onClick} size="medium"
        >
            <Typography>
                {email}
            </Typography>
        </Button>
    )
}

export default ChangeEmailButton;
