import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router';
import routes from '../helpers/routes';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import {FormattedMessage} from 'react-intl';

import AppLogo from '../components/AppLogo';
import {withClientConfig} from '../providers/ClientConfigProvider';

import {makeStyles} from '@material-ui/core/styles';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Link from "@material-ui/core/Link";
import qs from "qs";
import {isEmpty} from "../helpers/utils";

const useStyles = makeStyles(() => ({
    root: {
        height: '100vh',
        backgroundColor: '#f8f9fa'
    },
    paper: {
        width: '95%',
        maxWidth: '350px',
        overflow: 'hidden',
    },
    logo: {
        maxHeight: "60px",
        maxWidth: "75%"
    },
    app_logo: {
        width: "75%"
    }
}));

const Sso = ({history, location}) => {
    const classes = useStyles();
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        const queryParams = qs.parse(location.search, {ignoreQueryPrefix: true});
        if (queryParams?.code === 'ExpiredException') {
            setErrorMessage('Token expired');
        } else if (!isEmpty(queryParams.code)) {
            setErrorMessage('Authentication');
        }
    }, [location.search]);

    return (
        <Grid container spacing={0} direction="column" alignItems="center" justify="center"
              className={classes.root}>
            <Paper elevation={3} className={classes.paper}>
                <Box p={3}>

                    <Box mt={2} mb={2} justifyContent="center" display="flex">
                        <div className={classes.app_logo}><AppLogo/></div>
                    </Box>

                    <Typography variant="h5" align="center" gutterBottom>
                        <FormattedMessage id="Login.SignIn" defaultMessage="Log in"/>
                    </Typography>

                    {
                        isEmpty(errorMessage) &&
                        <Box mt={5}>
                            <Typography variant={"body1"} align={"center"}>
                                You currently do not have an account set up with us. Please contact
                                {" "}
                                <Link href="mailto:support@creatable.io" color="primary">
                                    support@creatable.io
                                </Link>
                                {" "}
                                for assistance.
                            </Typography>
                        </Box>
                    }
                    {
                        !isEmpty(errorMessage) &&
                        <>
                            <Box mt={5}>
                                <Typography variant={"body1"} align={"center"} component={'p'}>
                                    There was an error with authentication process.
                                </Typography>
                            </Box>
                            <Box mt={2}>
                                <Typography variant={"body1"} align={"center"} component={'p'} color={"error"}>
                                    Error: {errorMessage}
                                </Typography>
                            </Box>
                            <Box mt={2}>
                                <Typography variant={"body1"} align={"center"} component={'p'}>
                                    Please try again or contact
                                    {" "}
                                    <Link href="mailto:support@creatable.io" color="primary">
                                        support@creatable.io
                                    </Link>
                                    {" "}
                                    for assistance.
                                </Typography>
                            </Box>
                        </>
                    }

                    <Box mt={5} mb={2}>
                        <Button color="primary" onClick={() => history.push(routes.getSsoLoginUrl())} fullWidth
                                startIcon={<ArrowBackIcon/>}>
                            Back
                        </Button>
                    </Box>

                </Box>
            </Paper>
        </Grid>
    );
}

export default withClientConfig(withRouter(Sso));
