import React, {useEffect} from 'react';
import LoadingApp from "../components/LoadingApp";
import {useLocation, useParams} from "react-router-dom";
import qs from "qs";
import routes from "../helpers/routes";

const AzureSSO = () => {
    const {domain} = useParams();
    const {hash} = useLocation();

    useEffect(() => {
        const queryParams = qs.parse(hash.substring(1), {ignoreQueryPrefix: true});
        if (queryParams.hasOwnProperty('access_token')) {
            window.location.href = routes.getSSOAuthenticateURL(domain, queryParams.access_token);
        }
    }, [domain, hash]);

    return (
        <LoadingApp/>
    )
}

export default AzureSSO;
