import React, {useState, useEffect, useRef} from 'react';
import {withRouter} from 'react-router';
import routes from '../helpers/routes';

import * as EmailValidator from 'email-validator';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import {FormattedMessage} from 'react-intl';

import AppLogo from '../components/AppLogo';
import {withClientConfig} from '../providers/ClientConfigProvider';

import LinearProgress from '@material-ui/core/LinearProgress';
import Backdrop from '@material-ui/core/Backdrop';

import {makeStyles} from '@material-ui/core/styles';
import Api from "../helpers/api";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Link from "@material-ui/core/Link";
import {isEmpty} from "../helpers/utils";
import useNotification from "../helpers/useNotification";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        backgroundColor: '#f8f9fa'
    },
    paper: {
        width: '95%',
        maxWidth: '350px',
        overflow: 'hidden',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
    },
    logo: {
        maxHeight: "60px",
        maxWidth: "75%"
    },
    app_logo: {
        width: "75%"
    }
}));

const Sso = ({history}) => {
    const notification = useNotification();
    const classes = useStyles();
    const [inProgress, setInProgress] = useState(false);
    const [isIdentityProviderNotFound, setIsIdentityProviderNotFound] = useState(false);
    const [email, setEmail] = useState('');
    const [emailErrorMessage, setEmailErrorMessage] = useState('');

    const emailRef = useRef();

    useEffect(() => {
        emailRef.current && emailRef.current.focus();
    }, []);

    useEffect(() => {
        setEmailErrorMessage('');
    }, [email]);

    const onPressEnter = (callback) => (e) => {
        if (e.keyCode === 13) {
            callback();
        }
    };

    const validateDomain = () => {
        if (isEmpty(email) || !EmailValidator.validate(email)) {
            setEmailErrorMessage('Please enter a valid email');
            return;
        }
        setInProgress(true);
        const domain = email.split('@')[1];
        Api.getIdentityProviderSettingsByDomain(domain)(
            ({data}) => {
                window.location.href = data.redirect_url;
            },
            (error) => {
                setInProgress(false);
                if (error.response.status === 404) {
                    setIsIdentityProviderNotFound(true);
                } else {
                    notification.error(error);
                }
            }
        )({email});
    }

    return (
        <Grid container spacing={0} direction="column" alignItems="center" justify="center"
              className={classes.root}>
            <Paper elevation={3} className={classes.paper}>
                <Box p={3}>

                    <Box mt={2} mb={2} justifyContent="center" display="flex">
                        <div className={classes.app_logo}><AppLogo/></div>
                    </Box>

                    <Typography variant="h5" align="center" gutterBottom>
                        <FormattedMessage id="Login.SignIn" defaultMessage="Log in"/>
                    </Typography>

                    {
                        !isIdentityProviderNotFound &&
                        <>
                            <Box mt={4} mb={2}>
                                <FormControl fullWidth>
                                    <TextField label={"Email address"} variant="outlined"
                                               inputRef={emailRef}
                                               onKeyDown={onPressEnter(validateDomain)}
                                               onChange={(e) => setEmail(e.target.value.trim())}
                                               error={!isEmpty(emailErrorMessage)}
                                               autoFocus
                                               helperText={emailErrorMessage}/>
                                </FormControl>

                                <Box mt={2}>
                                    <Typography variant={"body1"}>
                                        This is your email address for the domain that has been configured as your SSO identity provider.
                                    </Typography>
                                </Box>

                                <Box mt={2}>
                                    <Button variant="contained" color="primary" disabled={inProgress}
                                            onClick={validateDomain}
                                            fullWidth>
                                        <FormattedMessage id="Login.Next" defaultMessage="Next"/>
                                    </Button>
                                </Box>
                            </Box>

                            <Box mt={4} mb={2}>
                                <Button color="primary" onClick={() => history.push(routes.getLoginUrl())} fullWidth
                                        startIcon={<ArrowBackIcon/>}>
                                    Back
                                </Button>
                            </Box>
                        </>
                    }
                    {
                        isIdentityProviderNotFound &&
                        <>
                            <Box mt={5}>
                                <Typography variant={"body1"} align={"center"}>
                                    We were unable to identify your SSO provider, please contact {" "}
                                    <Link href="mailto:support@creatable.io" color="primary">
                                        support@creatable.io
                                    </Link>
                                </Typography>
                            </Box>
                            <Box mt={5} mb={2}>
                                <Button color="primary" onClick={() => setIsIdentityProviderNotFound(false)} fullWidth
                                        startIcon={<ArrowBackIcon/>}>
                                    Back
                                </Button>
                            </Box>
                        </>
                    }

                </Box>
                {inProgress && <LinearProgress/>}
                <Backdrop className={classes.backdrop} open={inProgress}/>
            </Paper>
        </Grid>
    );
}

export default withClientConfig(withRouter(Sso));
