import React, {useEffect} from 'react';
import LoadingApp from "../components/LoadingApp";
import {useLocation, useParams} from "react-router-dom";
import qs from "qs";
import routes from "../helpers/routes";

const OktaSSO = () => {
    const {domain} = useParams();
    const {hash} = useLocation();

    useEffect(() => {
        const queryParams = qs.parse(hash.substring(1), {ignoreQueryPrefix: true});
        if (queryParams.hasOwnProperty('id_token')) {
            window.location.href = routes.getSSOAuthenticateURL(domain, queryParams.id_token);
        }
    }, [domain, hash]);

    return (
        <LoadingApp/>
    )
}

export default OktaSSO;
