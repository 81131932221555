import React from 'react';

import {VpnKey} from "@material-ui/icons";
import ConnectButton from "./components/ConnectButton/ConnectButton";
import {isEmpty} from "./helpers/utils";

const SsoConnectButton = ({onClick, title}) => {
    const getTitle = () => {
        return (isEmpty(title) ? "Continue with SSO" : title);
    }

    return (
        <ConnectButton icon={<VpnKey />} title={getTitle()} onClick={onClick}/>
    )
}

export default SsoConnectButton;
